import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Box,
  FormControl,
  Typography,
  Paper,
  Grid,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { testStatusListRequest } from "pages/test-status/slices/test-status-slice";
import SectionTable from "shared/organisms/tables/table";
import { isValidDateFormat, table } from "./helper";
import { downloadXlsxFile, isEmpty } from "utils/helpers";
import Loader from "pages/build-test/loader";
import testStatusService from "pages/test-status/service";
import { TableRowCollapse } from "./table";
import NoDataFound from "shared/molecules/no-data";

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const TestStatusMain = () => {
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [institutionType, setInstitutionType] = useState("college");

  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.testStatus);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleInstitutionChange = (event) => {
    setInstitutionType(event.target.value);
  };

  const handleDownload = async (mapping_id: number) => {
    const req = { mapping_id };
    try {
      const res = await testStatusService.getDownloadResultComparision(req);
      downloadXlsxFile(res, `resultcomparision_${mapping_id}.csv`);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
     if(isValidDateFormat(selectedDate)){
       dispatch(
         testStatusListRequest({ type: institutionType, date: selectedDate })
       );
     }
  }, [institutionType, selectedDate]);

  return (
    <div id="test-status-container">
      <Typography variant="h3" sx={{ marginBottom: "20px",fontSize:"34px" }}>
        Test Status Tracker
      </Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Box sx={{ width: "100%" }}>
          <Box
            id="filter-div"
            sx={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <TextField
                label="Select Date"
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "150px" }}
              />

              <FormControl sx={{ minWidth: 100 }}>
                <InputLabel id="institution-label">Institution</InputLabel>
                <Select
                  labelId="institution-label"
                  id="institution"
                  value={institutionType}
                  onChange={handleInstitutionChange}
                  label="Institution"
                  sx={{ width: "150px" }}
                >
                  <MenuItem value="school">School</MenuItem>
                  <MenuItem value="college">College</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 8,
                marginLeft: "250px",
                paddingTop: "10px",
              }}
            >
              <Typography component="p">
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
                S & S: Submitted Or Suspended
              </Typography>
              <Typography component="p">
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
                S & P: Started Or In_Progress
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            //width: "80%",
            // overflowX: "auto",
            // whiteSpace: "nowrap",
            // border: "1px solid red",
          }}
        >
          {loading && <Loader />}
          {/* <div id="table-test-status">
            {!loading && !isEmpty(data) && (
              <SectionTable
                data={data}
                table={table}
                id="narayana_mapping_id"
                name="narayana_mapping_id"
                handleEdit={""}
                handleDownload={handleDownload}
                //   handleCopy={handleCopy}
                rowsPerPageCount={50}
                // pagination="true"
                style="false"
                totlaRecords={0}
                // hasPageApiCall={false}
                paginationSegment="false"
                viewSecondaryData="false"
              />
            )}
          </div> */}
          {!loading && data?.length > 0 ? (
            <Box
              sx={{
                width: "100%",
                // height: "400px",
                marginTop: "25px",
                // padding: "20px",
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableRow
                    sx={{
                      // backgroundColor: "grey.200",
                      fontSize: "45px",
                      width: "100%",
                      "& td": {
                        fontSize: "15px",
                        fontWeight: "500",
                        textAlign: "start",
                      },
                    }}
                  >
                    <TableCell>Mapping ID</TableCell>
                    <TableCell>Exam Name</TableCell>
                    <TableCell>cbd_xml_updated_time</TableCell>
                    <TableCell>sbd_xml_downloaded_time</TableCell>
                    <TableCell>sbd_analysis_completed_status</TableCell>
                    <TableCell>Exam Start Date Time</TableCell>
                    <TableCell>Exam End Date Time</TableCell>
                    <TableCell>S & S Count*</TableCell>
                    <TableCell>S & P Count*</TableCell>
                  </TableRow>
                  <Box sx={{ width: "100%" }}></Box>
                  {!loading &&
                    data?.map((row: any) => (
                      <TableRowCollapse
                        row={row}
                        handleDownload={handleDownload}
                      />
                    ))}
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box> <NoDataFound message="" /> </Box>
          )}
        </Box>
      </Paper>
    </div>
  );
};
