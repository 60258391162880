import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  successMsg: {},
  filtersData: {},
};

const testStatusSlice = createSlice({
  name: "testStatusDownload",
  initialState,
  reducers: {
    testStatusListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    testStatusListSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    testStatusListFailure(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { testStatusListRequest, testStatusListSuccess,testStatusListFailure } =
  testStatusSlice.actions;
export default testStatusSlice.reducer;
