export const table = {
  primaryData: [
    {
      title: "Mapping ID",
      value: "narayana_mapping_id",
      type: "configue",
    },
    {
      title: "Name",
      value: "test_name",
      type: "configue",
    },
    {
      title: "cbd_xml_updated_time",
      value: "xml_updated_time",
      name: "update",
    },
    {
      title: "sbd_xml_downloaded_time",
      value: "xml_downloaded_time",
    },
    {
      title: "sbd_analysis_completed_status",
      value: "analytics_status",
    },
    {
      title: "Start Time",
      value: "test_start_time",
      type: "configue",
    },
    {
      title: "End Time",
      value: "test_end_time",
      type: "configue",
    },
    {
      title: "S & S Count*",
      value: "submitted_or_suspended_count",
    },
    {
      title: "S & P Count*",
      value: "started_or_progress_count",
      name:"name"
    },
  ],
  secondaryData: [],
};

//   {
//     "testId": "AI7U7kVDue",
//     "narayana_mapping_id": 101333,
//     "test_name": "jeeadv2011",
//     "test_start_time": "2024-09-04T06:30:00.000Z",
//     "test_end_time": "2024-09-14T12:30:00.000Z",
//     "analytics_status": false,
//     "download_status": false,
//     "xml_downloaded_time": "-",
//     "xml_updated_time": "-",
//     "submitted_or_suspended_count": 20,
//     "started_or_progress_count": 9
// },

export function formatDateTime(isoString) {
  if (!isoString || typeof isoString !== 'string') {
      return 'Invalid date';
  }

  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
      return 'Invalid date';
  }

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');

  // Extract time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format date and time
  //return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return `${day}-${month}-${year}  ${hours}:${minutes}:${seconds}`;
}

export function truncateText(text, maxLength = 20) {
  if (typeof text !== 'string') {
      return ''; // Return an empty string if input is not a valid string
  }

  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

export const isValidDateFormat=(dateString)=> {
  // Regular expression to match YYYY-MM-DD format
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  return regex.test(dateString);
}
