import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/redux";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { formatDateTime, truncateText } from "./helper";
export const TableRowCollapse = ({ row, handleDownload }) => {
  const [open, setOpen] = useState(false);

  return (
    <TableBody>
      <TableRow
        key={row?.id}
        sx={{
          "& td": {
            textAlign: "start",
            padding:"5px 10px",
            fontSize:"14px"
          },
          minHeight:"20px"
        }}
      >
        <TableCell>{row?.narayana_mapping_id}</TableCell>
        <TableCell>{row?.test_name}</TableCell>
        <TableCell>{row?.xml_updated_time}</TableCell>
        <TableCell>{row?.xml_downloaded_time}</TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "align-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {row?.analytics_status === true ? "True" : "False"}
            {row?.analytics_status === true ? (
              <FileDownloadIcon
                sx={{ color: "blue" }}
                onClick={() => {
                  handleDownload(row?.narayana_mapping_id);
                }}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell>{formatDateTime(row?.test_start_time)}</TableCell>
        <TableCell>{formatDateTime(row?.test_end_time)}</TableCell>
        <TableCell>{row?.submitted_or_suspended_count}</TableCell>
        <TableCell>{row?.started_or_progress_count}</TableCell>
      </TableRow>
    </TableBody>
  );
};
